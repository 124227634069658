import * as React from "react";

const VisiteVirtuelleReminiscence = () => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!4v1699256059155!6m8!1m7!1sCAoSLEFGMVFpcFBzaVZQSUlKZXRJaTlLVzN1dGZmZWQxanhRbUFOY19jQjlDYU1v!2m2!1d48.8699019!2d2.3188296!3f230.8627749234458!4f-2.8157203010453458!5f0.7820865974627469" 
      width="600" 
      height="450" 
        style={{ width: "100vw", height: "calc(100vh - 50px)" }}
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"
      title="Visite virtuelle de GHOST GALERIE"
      frameborder="0"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      allowvr="true"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    ></iframe>
    // <iframe
    //   title="Visite virtuelle de GHOST GALERIE"
   
    //   src="https://www.klapty.com/tour/tunnel/YQV0v9USAH"
    //   frameborder="0"
    //   allowfullscreen="true"
    //   mozallowfullscreen="true"
    //   webkitallowfullscreen="true"
    //   allowvr="true"
    //   allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    // ></iframe>
  );
};

export default VisiteVirtuelleReminiscence;
